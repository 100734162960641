'use strict'

const _ = require('lodash')
const runtimeDalFactory = require('../runtimeDal')
const {withActions} = require('carmi-host-extensions')

module.exports = {
    name: 'RuntimeAspect',
    defaultModel: {},
    functionLibrary: {
        updateCompState: (runtimeDal, runtimeStateMap, compId, partialOverrides) =>
            runtimeDal.updateCompState(compId, _.get(runtimeStateMap, compId), partialOverrides),
        setCompData: (runtimeDal, compId, partialOverrides) =>
            runtimeDal.setCompData(compId, partialOverrides),
        setCompProps: (runtimeDal, compId, partialOverrides) =>
            runtimeDal.setCompProps(compId, partialOverrides),
        resetRuntimeOverrides: withActions((actions, contextId, getOverridesToRemove) => {
            const overridesToRemove = _(getOverridesToRemove()).sortBy('path[0]').reverse().value()
            _.forEach(overridesToRemove, overrideToRemove => {
                const path = overrideToRemove.path
                overrideToRemove.remove(...path)
            })
            actions.setContextWasReset(contextId, true)
        }),

        deleteOldResetRuntimeEntries: withActions((actions, contextIdsToRemove) => {
            _.forEach(contextIdsToRemove, contextId => actions.setContextWasReset(contextId, undefined))
        })
    },
    init: (carmiInstance, {initialData, exceptionHandlingApi}) => {
        const runtimeDAL = runtimeDalFactory.create(carmiInstance, initialData.repeatersApi, exceptionHandlingApi)
        carmiInstance.setRuntimeDalInstance(runtimeDAL)
    }
}
