/*eslint no-unused-vars:0*/
import _ from 'lodash'
import {withActions} from '../withActions'
import {scrollUtils} from 'santa-core-utils'

export const name = 'WindowScrollAspect'

let nextID = 0 //eslint-disable-line santa/no-module-state
let $ //eslint-disable-line santa/no-module-state
const defaultScreenWidth = 1024
export const defaultModel = {
    position: {x: 0, y: 0},
    listeners: {},
    callbacks: {},
    direction: ''
}

/**
 *
 * @param {Position} position
 * @param {Position} prevPosition
 * @param {Direction} prevDirection
 * @returns {Direction}
 */
function getScrollDirection(position, prevPosition, prevDirection) {
    if (position.y !== prevPosition.y) {
        return position.y > prevPosition.y ? 'DOWN' : 'UP'
    }

    if (position.x !== prevPosition.x) {
        return position.x > prevPosition.x ? 'RIGHT' : 'LEFT'
    }
    return prevDirection
}

function willScrollY({clientHeight, scrollHeight, scrollPosition}, y) {
    if (scrollHeight <= clientHeight) {
        return false
    }

    const topScrollPosition = scrollPosition.y

    const bottomScrollPosition = topScrollPosition + clientHeight
    const willScrollDown = y > topScrollPosition && scrollHeight > bottomScrollPosition
    const willScrollUp = y < topScrollPosition && topScrollPosition > 0
    return willScrollUp || willScrollDown
}

export const functionLibrary = {
    registerToScroll: withActions(({setListener}, {props: {id}, onScroll}) => setListener(id, onScroll)),
    unregisterToScroll: withActions(({setListener}, {props: {id}}) => setListener(id, undefined)),
    scrollSiteTo: withActions(({setCallback}, scrollPosition, windowObject, x, y, callback): void => {
        // TODO: use layout aspect for document/window height instead of measuring it here.
        const {scrollTo, document: {documentElement: {clientHeight, scrollHeight}}} = windowObject
        if (callback) {
            if (!willScrollY({clientHeight, scrollHeight, scrollPosition}, y)) {
                callback()
                return
            }

            setCallback(++nextID, callback)
        }

        scrollTo(x, y)
    }),

    animatedScrollTo: (animationsInstance, windowObject, currentY, isMobileView, x, targetScrollY, callback, customDuration) => {
        const duration = _.isNumber(customDuration) ? customDuration : scrollUtils.calcScrollDuration(currentY, targetScrollY, isMobileView)
        const easingName = isMobileView ? 'Quint.easeOut' : 'Sine.easeInOut'
        if (animationsInstance) {
            animationsInstance.animate('BaseScroll', windowObject, duration, 0, {
                y: targetScrollY,
                ease: easingName,
                callbacks: {
                    onComplete: callback || _.noop
                }
            })
        }
    },
    propagateScrollEvent: withActions(({setPosition, setDirection, setCallback}, {callbacks, prevPosition, prevDirection, listeners, getCompRefs}, newPosition) => {
        const newDirection = getScrollDirection(newPosition, prevPosition, prevDirection)
        setPosition(newPosition)
        setDirection(newDirection)
        _.forEach(listeners, (onScroll, compId) => onScroll.call(getCompRefs()[compId], newPosition, newDirection))
        _.forEach(callbacks, (callback, id) => {
            callback(newPosition, newDirection)
            setCallback(id, undefined)
        })
    }),
    getWindowInnerWidth: _window => _window ? _window.innerWidth : defaultScreenWidth,
    scrollSiteBy: (_window: Window, x: number, y: number, callback: (...args: any[]) => void) => {
        if (_window) {
            _window.scrollBy(x, y)
            if (_.isFunction(callback)) {
                _window.requestAnimationFrame(callback)
            }
        }
    }
}

export function init({}, {eventsManager, initialData: {propagateScrollEvent}}) {
    eventsManager.on('windowScroll', ({pageXOffset, pageYOffset}) =>
        propagateScrollEvent({x: pageXOffset, y: pageYOffset})
    )
    $ = require('zepto')
}
