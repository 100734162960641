'use strict'

const _ = require('lodash')
const runtimeUtil = require('../../utils/runtime')
const {pointers, REPEATER_COMP_TYPE} = require('../../utils/constants')
const dataSchemas = require('./schemas/dataRefs')
const {createRepeaterRuntimeHandler} = require('./repeaterRuntimeHandler')

const {
    DATA_QUERY,
    PROPERTY_QUERY,
    DESIGN_QUERY,
    STYLE_ID
} = pointers.components.PROPERTY_TYPES

const {
    DATA,
    DESIGN,
    PROPERTIES,
    STYLE
} = pointers.data.DATA_MAPS

const getMapSchemas = mapName => {
    switch (mapName) {
        case DESIGN:
            return dataSchemas.Design
        case PROPERTIES:
            return dataSchemas.Properties
        default:
            return dataSchemas.Data
    }
}

const getShouldPreserveIds = dataItemType => dataItemType === 'StyledText'

module.exports = {
    create: (model, repeatersApi, exceptionHandlingApi) => {
        function addActionsAndBehaviors(compId, {action, behavior}) {
            model.spliceRuntimeBehavior(model.runtimeActionBehaviors.length, 0, {action, behavior, compId})
        }

        function removeActionsAndBehaviors(compId, actionName) {
            const actionBehaviorIdx = model.runtimeActionBehaviors.findIndex(actionBehavior =>
                actionBehavior.compId === compId && actionBehavior.action.name === actionName)
            if (actionBehaviorIdx > -1) {
                model.spliceRuntimeBehavior(actionBehaviorIdx, 1)
            }
        }

        const {handleRepeaterDataChange} = createRepeaterRuntimeHandler(model, addActionsAndBehaviors, removeActionsAndBehaviors)
        const registerComponentEvent = (compId, newEventData) => {
            const action = {
                type: 'comp',
                name: newEventData.eventType,
                sourceId: compId
            }
            const behavior = {
                name: 'runCode',
                type: 'widget',
                targetId: newEventData.contextId,
                params: {
                    callbackId: newEventData.callbackId,
                    compId
                }
            }

            model.spliceRuntimeBehavior(model.runtimeActionBehaviors.length, 0, {action, behavior, compId})
        }

        const getQuery = (structure, compId, queryName) => _.replace(structure[compId][queryName], '#', '')

        const setData = (compId, queryName, mapName, fullPartialOverrides) => { //eslint-disable-line consistent-return
            if (!_.get(model.structure, compId)) {
                return undefined
            }

            const structureQuery = getQuery(model.displayedStructureWithOverrides, compId, queryName)
            const schemas = getMapSchemas(mapName)

            const setResolvedDataItem = (query, itemPartialOverrides, options = {extendCurrentOverrides: true, shouldPreserveIds: false}) => {
                query = query.replace('#', '')
                const runtimeId = options.shouldPreserveIds ? itemPartialOverrides.id : runtimeUtil.getRuntimeId(compId, query)

                if (_.isNull(itemPartialOverrides)) {
                    model.updateRuntimeDataOverrides(mapName, runtimeId, undefined)
                    return undefined
                }

                const currentItem = model.data[mapName][query] || {}
                const dataItemType = itemPartialOverrides.type || currentItem.type
                if (!dataItemType && mapName !== PROPERTIES) {
                    return exceptionHandlingApi.throwException(new Error('No data type!'))
                }

                const dataOverrides = getDataOverridesWithRefs(itemPartialOverrides, currentItem, query, schemas[dataItemType])

                const runtimeDataItemId = dataItemType === 'Page' ? query : runtimeId
                const currentOverrides = model.runtime.data[mapName][runtimeDataItemId]

                if (dataItemType === 'Repeater') {
                    const originalDataQuery = getQuery(model.full.structure, compId, DATA_QUERY)
                    const originalDataItem = model.full.data[DATA][originalDataQuery]
                    const repeaterOverrides = repeatersApi.shouldIgnoreRepeaterOverrides() ? null : currentOverrides
                    handleRepeaterDataChange(compId, originalDataItem, repeaterOverrides, dataOverrides)
                }

                if (options.extendCurrentOverrides) {
                    model.updateRuntimeDataOverrides(mapName, runtimeDataItemId, dataOverrides)
                } else {
                    model.setRuntimeDataOverrides(mapName, runtimeDataItemId, [dataOverrides])
                }

                return runtimeDataItemId
            }

            const shouldResolveRef = dataItem => !(dataItem && dataItem.type === 'Page')

            function getDataOverridesWithRefs(partialOverrides, compData = {}, query, refsSchema) {
                const dataItemType = partialOverrides.type || compData.type
                return _.mapValues(partialOverrides, (partialValue, key) => {
                    const refSchemaValue = _.get(refsSchema, key)
                    const isRefItem = _.isBoolean(refSchemaValue) && !!partialValue
                    const isNestedRef = _.isObject(refSchemaValue) && _.isObject(partialValue)

                    if (isNestedRef) {
                        return getDataOverridesWithRefs(partialValue, compData[key], `${query}${key}`, refSchemaValue)
                    }

                    if (!isRefItem || refSchemaValue === true && _.isString(partialValue)) {
                        return partialValue
                    }

                    if (_.isArray(partialValue)) {
                        return _.map(partialValue, (refPartialOverrides, index) =>
                            setResolvedDataItem(
                                `${query}${key}${index}`,
                                _.assign({}, refPartialOverrides, {metaData: compData.metaData}),
                                {
                                    extendCurrentOverrides: false,
                                    shouldPreserveIds: getShouldPreserveIds(dataItemType)
                                }
                            )
                        )
                    }

                    const existingDataId = _.get(compData[key], 'id', compData[key])
                    const dataId = (existingDataId || `${query}${key}`).replace('#', '')
                    return shouldResolveRef(partialValue) ? setResolvedDataItem(dataId, partialValue, {extendCurrentOverrides: false}) : dataId
                })
            }

            setResolvedDataItem(structureQuery || queryName, fullPartialOverrides)
        }

        const getData = (compId, queryName, mapName) => {
            if (!_.get(model.structure, compId)) {
                return undefined
            }

            const query = getQuery(model.structure, compId, queryName)
            return model.data[mapName][query]
        }

        function handleSetRepeaterData(compId, partialOverrides, isDuringBatch) {
            model.$runInBatch(() => {
                setData(compId, DATA_QUERY, DATA, partialOverrides)
            })

            if (isDuringBatch) {
                model.$startBatch()
            }
        }

        return {
            getCompData: compId => getData(compId, DATA_QUERY, DATA),
            getCompProps: compId => getData(compId, PROPERTY_QUERY, PROPERTIES),
            getPopupContext: _.noop,
            registerComponentEvent,
            setCompData: (compId, partialOverrides, isDuringBatch) => {
                const isRepeater = _.get(model.structure, [compId, 'componentType']) === REPEATER_COMP_TYPE
                if (isRepeater) {
                    handleSetRepeaterData(compId, partialOverrides, isDuringBatch)
                } else {
                    setData(compId, DATA_QUERY, DATA, partialOverrides)
                }
            },
            setCompDesign: (compId, partialOverrides) => setData(compId, DESIGN_QUERY, DESIGN, partialOverrides),
            setCompProps: (compId, partialOverrides) => setData(compId, PROPERTY_QUERY, PROPERTIES, partialOverrides),
            updateCompState: (compId, currentOverrides, partialOverrides) => {
                if (partialOverrides && _.isEqual(currentOverrides, partialOverrides)) {
                    return
                }
                const overrides = partialOverrides ? _.assign({}, currentOverrides, partialOverrides) : undefined
                model.updateRuntimeCompState(compId, overrides)
            },
            addActionsAndBehaviors,
            removeActionsAndBehaviors,
            updateCompLayout: _.noop,
            updateCompStyle: (compId, partialOverrides) => setData(compId, STYLE_ID, STYLE, partialOverrides)
        }
    }
}
