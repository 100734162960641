import _ from 'lodash'
import {withActions} from '../withActions'
import {containerBackgroundUtils} from 'santa-core-utils'

export const name = 'MediaAspect'
export const constants = {
    REGISTERED_PLAYERS: 'registeredPlayers',
    PLAYBACK_STATE: 'playbackState',
    QUALITY_STATE: 'qualityState'
}

export const defaultModel = {
    [constants.REGISTERED_PLAYERS]: {},
    [constants.PLAYBACK_STATE]: {},
    [constants.QUALITY_STATE]: {}
}

export const functionLibrary = {

    updatePlayerState: withActions((aspectActions, currentState, id, newState) =>
        aspectActions.updatePlayerState(id, {...currentState, ...newState})),

    updateQualityState: withActions((aspectActions, currentState, id, newState) =>
        aspectActions.updateQualityState(id, {...currentState, ...newState})),

    initFeatureDetections: withActions((aspectActions, shouldInit, windowObject) => {
        if (shouldInit) {
            const playsInline = /(iPhone|iPod)/g.test(windowObject.navigator.userAgent) ? 'playsInline' in windowObject.document.createElement('video') : true
            aspectActions.updateQualityState('featureDetections', {playsInline})
        }
    }),
    getImageUrlParams(...params) {
        return containerBackgroundUtils.getImageUrlPreMeasureParams(...params)
    },

    registerPlayer: withActions((aspectActions, id, viewportAPI, isInSSR, externalId, options) => {
        if (isInSSR) {
            return
        }
        options = options || {}
        const mediaData = _.get(options, 'mediaData')
        const videoId = _.get(mediaData, 'videoId')

        aspectActions.updatePlayerState(id, {
            playerType: options.playerType || 'none',
            videoId,
            duration: _.get(mediaData, 'duration', 0)
        })
        aspectActions.updateRegisteredPlayers(id, id)

        const services = options.services || {}

        if (services.viewport) {
            viewportAPI.register(name, id, services.viewport.callback)
        }
    }),

    unregisterPlayer: withActions((aspectActions, id, viewportAPI) => {
        aspectActions.updateRegisteredPlayers(id)
        aspectActions.updatePlayerState(id, undefined)
        viewportAPI.unregister(name, id)
    })
}
