import * as RenderFlags from './aspects/renderFlags/renderFlags'
import * as Audio from './aspects/audio/audio'
import * as BgScrub from './aspects/bgScrub/bgScrub'
import * as ScrollScrub from './aspects/scrollScrub/scrollScrub'
import * as Browser from './aspects/browser/browser'
import * as TranslationsLoader from './aspects/translationsLoader/translationsLoader'
import * as Fonts from './aspects/fonts/fonts'
import * as Media from './aspects/media/media'
import * as WindowObject from './aspects/windowObject/windowObject'
import * as ScreenDimensions from './aspects/screenDimensions/screenDimensions'
import * as WindowScroll from './aspects/windowScroll/windowScroll'
import * as VectorImage from './aspects/vectorImage/vectorImage'
import * as FullScreen from './aspects/fullScreen/fullScreen'
import * as Logger from './aspects/logger/logger'
import * as WindowClickEvent from './aspects/windowClickEvent/windowClickEvent'
import * as Layout from './aspects/layout/layout'
import * as DesignDataChange from './aspects/designDataChange/designDataChange'
import * as siteScrollingBlocker from './aspects/siteScrollingBlocker/siteScrollingBlocker'
import * as NonPageItemZoom from './aspects/NonPageItemZoom/NonPageItemZoom'
import * as Stub from './aspects/stub/stub'
import * as ExecuteAnimationAspect from './aspects/executeAnimationBehaviors/executeAnimationBehaviors'

//import * as Viewport from './aspects/viewport/viewport'
import * as Animation from './aspects/animation/animation'
import * as ScreenIn from './aspects/screenIn/screenIn'
import * as UrlUtils from './aspects/utils/url/url'
import * as WindowFocus from './aspects/windowFocus/windowFocus'
import * as WindowTouchEvents from './aspects/windowTouchEvents/windowTouchEvents'
import * as WindowKeyboard from './aspects/windowKeyboard/windowKeyboardEvent'
import * as Viewport from './aspects/viewport/viewport'
import * as SiteVisibility from './aspects/siteVisibility/siteVisibility'

export {withActions} from './aspects/withActions'
export const runtime = {
    siteScrollingBlocker,
    RenderFlags,
    Audio,
    Browser,
    TranslationsLoader,
    NonPageItemZoom,
    Media,
    Animation,
    VectorImage,
    FullScreen,
    Fonts,
    WindowObject,
    WindowClickEvent,
    ScreenDimensions,
    WindowScroll,
    Logger,
    Layout,
    UrlUtils,
    WindowFocus,
    WindowTouchEvents,
    WindowKeyboard,
    DesignDataChange,
    Viewport,
    ScreenIn,
    BgScrub,
    ScrollScrub,
    SiteVisibility,
    Stub,
    ExecuteAnimationAspect
}
