import _ from 'lodash'
import ReactDOM from 'react-dom'

export {handleName as name} from '../platform.schema'
import {functionLibrary as PlatformHandlers} from './platformHandlers'
import {resolveData} from '../services/resolveData'
import {handleBatchCommand} from './handleBatchCommand'
import {splitToCarmiBatches} from './plaformMessagesBatcher'
import {getResponseMessage} from './apiCallsHandler'
import {withActions} from 'carmi-host-extensions'

const coreUtilsLib = require('coreUtils')

const MaxDebounceOptions = {
    wait: 10,
    maxWait: 100
}

const CommandTypes = {
    State: 'stateChanged',
    Data: 'dataChanged',
    Design: 'designChanged',
    Props: 'propsChanged',
    EventRegister: 'registerEvent',
    Layout: 'layoutChanged',
    Behavior: 'executeBehavior',
    Style: 'styleChanged',
    ExecuteBatch: 'executeBatch'
}

const messagesBatch = new WeakMap()

function handleCommand(actions, {runtimeDal, resolverGeneralInfo, handleBehavior, wixCodeAppApi}, contextId, message) {
    switch (message.command) {
        case CommandTypes.Data:
            message.data = resolveData(runtimeDal, message.compId, message.data, resolverGeneralInfo)
            runtimeDal.setCompData(message.compId, message.data)
            break
        case CommandTypes.Props:
            runtimeDal.setCompProps(message.compId, message.data)
            coreUtilsLib.animationFrame.request(() => {
                sendResponse(message, wixCodeAppApi[contextId], CommandTypes.Props)
            }) //this is roughly how it's done in santa
            break
        case CommandTypes.Design:
            runtimeDal.setCompDesign(message.compId, message.data)
            break
        case CommandTypes.Behavior:
            handleBehavior(_.assign({}, message.data, {
                callback: paramData => {
                    coreUtilsLib.animationFrame.request(() => {
                        sendResponse(message, wixCodeAppApi[contextId], CommandTypes.Behavior, paramData)
                    })
                }
            }))
            break
        case CommandTypes.Layout:
            throw new Error('Command type is not implemented', message.command)
        case CommandTypes.Style:
            runtimeDal.updateCompStyle(message.compId, message.data)
            break
        case CommandTypes.ExecuteBatch:
            handleBatchCommand(actions, runtimeDal, message.data, resolverGeneralInfo)
            break
        case CommandTypes.EventRegister:
            runtimeDal.registerComponentEvent(message.compId, message.data)
            break
    }
}

function sendResponse(message, wixCodeAppApi, commandName, data) {
    if (!message.callbackId) {
        return
    }
    const dataToSend = {
        command: commandName,
        compId: message.compId
    }
    if (data) {
        _.merge(dataToSend, data)
    }

    wixCodeAppApi.sendMessage(getResponseMessage(message, dataToSend))
}

function handleSystemMessage(actions, messageHandleParams, contextId, message) {
    switch (message.type) {
        case 'widget_ready':
            messageHandleParams.setIsReady(contextId, true)
            messageHandleParams.setWidgetReady(true)
            break
        case 'console':
            coreUtilsLib.logWixCodeConsoleMessage(message)
            break
        case 'wix_code_iframe_command':
            handleCommand(actions, messageHandleParams, contextId, message)
            break
        case 'REQUEST_API':
            if (!messageHandleParams.isInSsr) {
                messageHandleParams.requireFn('pmrpc', pmrpc => {
                    const apiName = `viewer_platform_public_api_${message.appDefId}_${message.workerId}`
                    pmrpc.api.request(apiName, {
                        target: {
                            postMessage: (msg, filter, ports) => {
                                messageHandleParams.api.sendMessage(msg, ports)
                            },
                            addEventListener: (evtType, handler) => {
                                if (evtType === 'message') {
                                    messageHandleParams.api.listen(handler)
                                }
                            }
                        }
                    })
                        .then(api => pmrpc.api.set(apiName, api))
                })
            }
            break
        //we set this data, but we do not use it for now, cause it will force us to postpone the boostrap message which isn't a good idea for sure
        case 'wix_code_warmup_data':
            const controllerId = _.get(message, 'data.controllerId')
            const data = _.get(message, 'data.data')
            messageHandleParams.setUserWarmup(controllerId, data)
            break
        case 'performance_metrics_ready':
            if (typeof window !== 'undefined') {
                window.postMessage(message, '*')
            }
            break
        default:
            console.log('not implemented', message.type, message)
    }
}

function setStorageAndUpdateWorkers({wixCodeAppApi, storage}, message) {
    const {type, key, value} = message.data
    storage.setItem(type, key, value)
    const storageUpdatedMessage = {
        type: 'storageWasUpdated',
        storageValue: storage.serialize()
    }
    _.forEach(wixCodeAppApi, worker => {
        worker.sendMessage(storageUpdatedMessage)
    })
}

function handleBrowserApiMessage(messageHandleParams, message) {
    switch (message.type) {
        case 'setToStorage':
            // console.log('setToStorage', message)
            setStorageAndUpdateWorkers(messageHandleParams, message)
            break
        default:
            console.log('not implemented', message.intent, message)
    }
}

function handleSingleMessage(actions, messageHandleParams, contextId, message) {
    switch (message.intent) {
        case 'WIX_CODE_SITE_API':
            const uniqueMessageId = _.uniqueId('wixCodeApiCall-')
            message.__uniqueId = uniqueMessageId
            message.__cId = contextId
            message.__api = messageHandleParams.api
            messageHandleParams.setApiCallMessage(message.__uniqueId, message)
            break
        case 'WIX_CODE':
            handleSystemMessage(actions, messageHandleParams, contextId, message)
            break
        case 'BROWSER_API':
            handleBrowserApiMessage(messageHandleParams, message)
            break
        case 'WIX_CODE_SSR':
            // not relevant in bolt
            break
        default:
            console.log('not implemented', message.intent, message)
    }
}

export const defaultModel = {
    isReady: {},
    apiCalls: {},
    userWarmup: {},
    debounceOptions: MaxDebounceOptions
}

export const functionLibrary = {
    deleteIsReadyEntry: (contextId, setIsReady) => {
        setIsReady(contextId, false)
        return false
    },

    handlePlatformMessage: withActions((actions, messageHandleParams, {wait, maxWait}, contextId, postMessage) => {
        const message = postMessage.data
        if (!messagesBatch.has(actions)) {
            const handler = _.debounce(() => {
                const batch = messagesBatch.get(actions).messages
                const batchSize = batch.length
                messagesBatch.delete(actions)
                if (batchSize < 5) {
                    actions.setMaxWait(Math.max(maxWait - 1, MaxDebounceOptions.wait))
                }
                if (batchSize > 20) {
                    actions.setMaxWait(Math.min(maxWait + 1, MaxDebounceOptions.maxWait))
                }
                ReactDOM.unstable_batchedUpdates(() => {
                    const {batches, noBatch} = splitToCarmiBatches(batch)
                    batches.forEach(carmiBatch => {
                        actions.$runInBatch(() => {
                            messageHandleParams.setIsHandlingMessage(true)
                            carmiBatch.forEach(entry => handleSingleMessage(actions, messageHandleParams, entry.contextId, entry.message))
                        })
                        messageHandleParams.setIsHandlingMessage(false)
                    })
                    noBatch.forEach(entry => handleSingleMessage(actions, messageHandleParams, entry.contextId, entry.message))
                })
            }, wait, {leading: false, trailing: true, maxWait})
            messagesBatch.set(actions, {messages: [], handler})
        }
        messagesBatch.get(actions).messages.push({message, contextId})
        messagesBatch.get(actions).handler()
    }),
    ...PlatformHandlers
}
