import _ from 'lodash'
const {withActions} = require('carmi-host-extensions')

export const name = 'TPAWidgetNativeAspect'
export const functionLibrary = {
    setReactComponent: withActions((actions, widgetId, obj) => {
        const reactComp = _.get(obj, 'default.component')
        actions.setNativeComponentData(widgetId, {component: reactComp})
    }),
    getEditorIndex: colorName => Number(colorName.split('_').pop()),
    capitalize: v => _.capitalize(v)
}

export const defaultModel = {
    data: {},
    props: {}
}

