import {withActions} from '../withActions'
import * as constants from 'santa-renderer/src/utils/constants'
import _ from 'lodash'
import reactDOM from 'react-dom'
import {siteConstants} from 'santa-core-utils'
import {defaultLayoutProps} from './layoutProps'
const layoutUtilsFactory = require('carmi-host-extensions/src/layout/layoutUtils')

const name = 'LayoutAspect'

let layoutUtils // eslint-disable-line santa/no-module-state
let compRefs // eslint-disable-line santa/no-module-state

const defaultModel = {
    pendingReLayoutComps: {},
    measureMap: {},
    layoutCounter: 0,
    isLayoutUtilsInitialized: false,
    scheduledLayoutPromise: null,
    fontLoaded: false,
    windowResized: false,
    layoutProps: defaultLayoutProps
}

function init(aspectActions, {eventsManager, initialData}) {
    compRefs = initialData.compRefs

    if (!initialData.isInSSR) {
        eventsManager.on(constants.EVENTS.FONT_LOAD, () => aspectActions.setFontLoaded(true))
    }
}

function triggerComponentDidLayoutRecursive(comp) {
    if (comp.componentDidLayout) {
        comp.componentDidLayout.call(comp)
    }
    if (comp.componentDidLayoutAnimations) {
        comp.componentDidLayoutAnimations.call(comp)
    }
    _.forOwn(comp.refs, triggerComponentDidLayoutRecursive)
}

const functionLibrary = {
    getLayoutMechanism: (currentUrl, siteMeshReadyFallbackFlag, browserFlags, masterPageLayoutSettings, isExperimentOpen) => {
        const forcedLayoutMechanism = _.get(currentUrl, ['query', 'layoutMechanism'])
        if (forcedLayoutMechanism) {
            return forcedLayoutMechanism
        }
        if (!isExperimentOpen.sv_meshLayout || !browserFlags.cssGridSupported) {
            return siteConstants.LAYOUT_MECHANISMS.ANCHORS
        }
        if (_.has(masterPageLayoutSettings, 'mechanism')) {
            return masterPageLayoutSettings.mechanism
        }
        if (isExperimentOpen.sv_meshReadyFallback && siteMeshReadyFallbackFlag) {
            return siteConstants.LAYOUT_MECHANISMS.MESH
        }
        return siteConstants.LAYOUT_MECHANISMS.ANCHORS
    },
    generateAnchors: ({requireFn, pageComps, pageId, flags, siteTheme, componentsTemplateId, viewMode, shouldApplyMobileTightLayout}) => {
        const coreUtils = requireFn('coreUtils')
        const deepStructure = coreUtils.flatStructureUtil.buildDeepStructure(pageComps[pageId], pageComps)
        if (pageId === 'masterPage' && flags.forceMobileStructure && shouldApplyMobileTightLayout) {
            deepStructure.mobileComponents = deepStructure.children
            return {
                [viewMode]: coreUtils.layoutAnchors.createMobileTightMasterPageAnchors(deepStructure, siteTheme, flags)
            }
        }
        return {
            [viewMode]: coreUtils.layoutAnchors.createPageAnchors(deepStructure, siteTheme, flags, componentsTemplateId)
        }
    },
    isMasterPagesSectionsTight(masterPageSectionLayouts) {
        for (let i = 0; i < masterPageSectionLayouts.length - 1; i++) {
            const currentSection = masterPageSectionLayouts[i]
            const nextSection = masterPageSectionLayouts[i + 1]
            const currentSectionBottom = currentSection.y + currentSection.height
            const nextSectionTop = nextSection.y
            if (currentSectionBottom !== nextSectionTop) {
                return false
            }
        }
        return true
    },
    registerLayoutFuncForNode: (domNode, func) => {
        layoutUtils.registerLayoutFunc(domNode, func)
    },
    registerReLayoutPendingForComp: withActions((aspectActions, compId) => {
        aspectActions.markPendingReLayout(compId, true)
    }),
    runLayout: withActions((aspectActions, checkCanRunLayout, getCurrentLayoutData, getLayoutCounter, reportInteractive) => {
        if (!checkCanRunLayout) {
            aspectActions.setScheduledLayoutPromise(null)
            return
        }
        aspectActions.$runInBatch(() => {
            let layoutResult = {
                measureMap: {}
            }
            try {
                layoutResult = layoutUtils.runLayout({...getCurrentLayoutData(), compRefs})
            } catch (e) {
                console.error(e)
            }

            const {measureMap} = layoutResult
            const currentLayoutCounter = getLayoutCounter()
            if (currentLayoutCounter === 0) {
                reportInteractive()
            }
            aspectActions.setPendingReLayoutComps({})
            aspectActions.setFontLoaded(false)
            aspectActions.setWindowResized(false)
            aspectActions.setLayoutCounter(currentLayoutCounter + 1)
            aspectActions.setMeasureMap(measureMap)
            aspectActions.setScheduledLayoutPromise(null)
        })
    }),
    triggerLayout: withActions(({setScheduledLayoutPromise}, runLayout) => {
        if (runLayout) {
            setScheduledLayoutPromise(Promise.resolve().then(runLayout))
        }
    }),
    initLayoutUtils: withActions((aspectActions, fullLayoutData, isExperimentOpen) => {
        const warmupUtils = require('warmupUtils')
        const warmupUtilsLib = require('warmupUtilsLib')
        const layout = require('layout')

        layoutUtils = layoutUtilsFactory.init({
            layoutData: fullLayoutData,
            isExperimentOpen,
            requestRelayout: () => aspectActions.markPendingReLayout('compId', true),
            dependencies: {warmupUtils, warmupUtilsLib, layout, lodash: _, reactDOM}
        })
        aspectActions.setIsLayoutUtilsInitialized(true)
    }),
    triggerComponentDidLayout: layoutCounter => {
        if (layoutCounter > 0) {
            _.forEach(compRefs, triggerComponentDidLayoutRecursive)
        }
    },
    showPage({layoutCounter, compRef}) {
        if (layoutCounter && compRef) {
            reactDOM.findDOMNode(compRef).style.visibility = '' // eslint-disable-line react/no-find-dom-node
        }
    },
    removeImage: id => layoutUtils.imageLoader.removeImage(id),
    getCustomMeasureMap: (customMeasureMap, customId) => _.get(customMeasureMap, customId)
}

export {
    name,
    defaultModel,
    init,
    functionLibrary
}
