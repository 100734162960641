import _ from 'lodash'

const createBatches = messages => {
    const counters = {}
    const batches = []

    const appendMessageToBatch = (m, batchNumber) => {
        batches[batchNumber] = batches[batchNumber] || []
        batches[batchNumber].push(m)
    }

    for (let i = 0; i < messages.length; i++) {
        const message = messages[i]
        const messageData = message.message

        if (messageData.command === 'executeBatch') {
            const lastBatchIndex = Math.max(0, batches.length - 1)
            appendMessageToBatch(message, lastBatchIndex)
            return batches.concat(createBatches(messages.slice(i + 1)))
        }

        const commandCounter = _.get(counters, [messageData.compId, messageData.command], 0)
        appendMessageToBatch(message, commandCounter)
        _.set(counters, [messageData.compId, messageData.command], commandCounter + 1)
    }

    return batches
}

const splitToCarmiBatches = batch => {
    const [wixCodeMessages, otherMessages] = _.partition(batch, {message: {intent: 'WIX_CODE', type: 'wix_code_iframe_command'}})

    const batches = createBatches(wixCodeMessages)

    return {
        batches,
        noBatch: otherMessages
    }
}


export {splitToCarmiBatches}
