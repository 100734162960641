import coreUtils from 'santa-core-utils'
const {ALIGNMENTS} = coreUtils.constants.COMP_LAYOUT_OPTIONS.REPEATER

const name = 'repeater'

function getNumOfItemsInRow(repeaterWidth, itemWidth, horizontalGap) {
    if (repeaterWidth < itemWidth) {
        return 1
    }
    return Math.floor((repeaterWidth - itemWidth) / (itemWidth + horizontalGap)) + 1
}

function getAlignmentOffset(alignment, rowLeftoverWidth, column, itemsInRow) {
    switch (alignment) {
        case ALIGNMENTS.RIGHT: 
            return rowLeftoverWidth
        case ALIGNMENTS.CENTER: 
            return rowLeftoverWidth / 2
        case ALIGNMENTS.JUSTIFY:
            const itemsToJustify = itemsInRow > 1 ? itemsInRow - 1 : 1
            return rowLeftoverWidth / itemsToJustify * column
        default: 
            return 0
    }
}

function getHorizontalGapOffset(alignment, column, horizontalGap) {
    if (alignment !== ALIGNMENTS.JUSTIFY) {
        return horizontalGap * column
    }
    return 0
}

const functionLibrary = {
    getReapterItemLayout: ({itemsLayoutProps, templateLayout, repeaterWidth, numOfItems}, index) => {
        const horizontalGap = itemsLayoutProps.alignment !== ALIGNMENTS.JUSTIFY ? itemsLayoutProps.gap.horizontal : 0
        const verticalGap = itemsLayoutProps.gap.vertical
        const itemsInRow = getNumOfItemsInRow(repeaterWidth, templateLayout.width, horizontalGap)
        const maxActualItemsInRow = Math.min(itemsInRow, numOfItems)
        const rowLeftoverWidth = repeaterWidth - horizontalGap * (maxActualItemsInRow - 1) - templateLayout.width * maxActualItemsInRow // eslint-disable-line no-mixed-operators
        const column = index % itemsInRow
        const row = Math.floor(index / itemsInRow)

        const itemAlignmentOffset = getAlignmentOffset(itemsLayoutProps.alignment, rowLeftoverWidth, column, maxActualItemsInRow)
        const itemHorizontalGapOffset = getHorizontalGapOffset(itemsLayoutProps.alignment, column, horizontalGap)

        return {
            ...templateLayout,
            x: column * templateLayout.width + itemAlignmentOffset + itemHorizontalGapOffset,
            y: (verticalGap + templateLayout.height) * row
        }
    }
}

export {functionLibrary, name}